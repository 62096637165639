import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { Dashboard, DashboardBox } from '@marvelapp/ui-internal';

import {
  colors,
  DRIBBBLE_ICON,
  GITHUB_ICON,
  SIDEBAR_WIDTH,
  TWITTER_ICON,
} from '@marvelapp/theme';

import {
  Box,
  hexToRGB,
  Icon,
  Link,
  LogoText,
  Position,
  ShareButton,
  Sidebar,
  Flex,
} from '@marvelapp/ui';

import { SIDEBAR_LINKS } from '../../content/sidebar';

const TWITTER_URL = 'https://twitter.com/marvelapp';
const DRIBBBLE_URL = 'https://dribbble.com/marvelapp';
const GITHUB_URL = 'https://github.com/marvelapp';

export default function Page({ children, location }) {
  const { pathname } = location;
  return (
    <>
      <Sidebar maxHeight="100%" overflow="auto">
        <Box pb={5} position="relative" pt={[5, 44]} px={3} width={1}>
          <Box mb={[4, 5]} p={1} px={3}>
            <GatsbyLink to="/">
              <LogoText color="marvel" text="Docs" />
            </GatsbyLink>
          </Box>
          {SIDEBAR_LINKS.map((link) => {
            const isActive = pathname.includes(link.to);
            return (
              <Box>
                <Link
                  as={GatsbyLink}
                  bg={isActive ? hexToRGB(colors.snowDark, 0.6) : 'white'}
                  borderRadius={2}
                  color={isActive ? 'licorice' : 'slate'}
                  display="block"
                  fontSize={14}
                  fontWeight={isActive ? 500 : 400}
                  height="32px"
                  hoverBg={isActive ? 'snowDark' : 'snow'}
                  hoverColor="licorice"
                  lineHeight="32px"
                  marginBottom={2}
                  pl={3}
                  pr={3}
                  pt="1px"
                  to={link.to}
                >
                  {link.name}
                </Link>
                {link.sublinks && pathname.includes(link.to) && (
                  <Box marginBottom={3}>
                    {link.sublinks.map((sublink) => {
                      const isSubActive =
                        pathname === sublink.to ||
                        pathname === `${sublink.to}/`;
                      return (
                        <Link
                          as={GatsbyLink}
                          borderRadius={2}
                          color={isSubActive ? 'licorice' : 'slate'}
                          display="block"
                          fontSize={14}
                          fontWeight={isSubActive ? 500 : 400}
                          height="32px"
                          hoverBg="snow"
                          hoverColor="licorice"
                          lineHeight="32px"
                          marginBottom={2}
                          pl={26}
                          pr={26}
                          pt="1px"
                          to={sublink.to}
                          whiteSpace="nowrap"
                        >
                          {sublink.name}
                        </Link>
                      );
                    })}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Position bottom={0} position="sticky" px={5} py={4} right={0}>
          <Flex justifyContent="center" width={1}>
            <ShareLink href={GITHUB_URL} mr={12} type="github">
              <Icon height={20} paths={GITHUB_ICON} width={20} />
            </ShareLink>
            <ShareLink href={DRIBBBLE_URL} mr={12} type="dribbble">
              <Icon height={20} paths={DRIBBBLE_ICON} width={20} />
            </ShareLink>
            <ShareLink href={TWITTER_URL} type="twitter">
              <Icon height={20} paths={TWITTER_ICON} width={20} />
            </ShareLink>
          </Flex>
        </Position>
      </Sidebar>
      <Dashboard sidebarWidth={SIDEBAR_WIDTH}>
        <Box maxWidth={780}>
          <DashboardBox>{children}</DashboardBox>
        </Box>
      </Dashboard>
    </>
  );
}

const ShareLink = (props) => <ShareButton as="a" target="_blank" {...props} />;
