import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { FlexWrap, Flex, Heading, Text, Box } from '@marvelapp/ui';
import { space } from '@marvelapp/theme';

import Page from '../../components/Page';
import PageMeta from '../../components/PageMeta';

export default function IntroductionPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Page location={location}>
      <PageMeta title={siteTitle} />
      <Heading as="h1" fontSize={[6, 7]} marginTop={[6, 7, 104]}>
        Marvel Docs
      </Heading>
      <Text fontSize={[18, 22]} lineHeight={1.7}>
        Marvel, both as a product and a company, has grown a lot over the years.
        One challenge we are always faced with is learning how to refine the
        Marvel brand identity and apply it cohesively to each of our products.
      </Text>

      <Flex
        alignItems="center"
        borderRadius={3}
        boxShadow="popover.default"
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
        marginLeft={[0, 0, 0, -6]}
        marginTop={6}
        px={[4, 5, 5, 6]}
        py={[4, 5, 5, 2]}
        width={[1, 1, 1, 'calc(100% + 96px)']}
      >
        <Box paddingRight={5} width={[1, 1, 1, 4 / 6]}>
          <Text fontSize={18} lineHeight={1.7}>
            These docs aim to act as a central location where we house a live
            inventory of UI components, brand guidelines, brand assets, code
            snippets, developer guidelines and more. Anyone working at Marvel,
            both product and engineering, is encouraged to stay familiar with
            these docs and help ensure that it is kept up-to-date.
          </Text>
        </Box>
        <Box width={[1, 1, 2 / 3, 2 / 6]}>
          <Img fluid={data.build.childImageSharp.fluid} />
        </Box>
      </Flex>

      <Heading as="h2" fontSize={6} marginTop={7}>
        Goals
      </Heading>

      <FlexWrap marginTop={5} space={space[3]}>
        <Box marginBottom={[4, 4, 4, 0]} px={3} width={[1, 1, 1, 1 / 3]}>
          <Heading fontSize={4} fontWeight={500} mb={2}>
            Cohesive design language
          </Heading>
          <Text fontSize={3} lineHeight={1.6}>
            Refine our visual language to be simpler, more fun and more
            communicative.
          </Text>
        </Box>
        <Box marginBottom={[4, 4, 4, 0]} px={3} width={[1, 1, 1, 1 / 3]}>
          <Heading fontSize={4} fontWeight={500} mb={2}>
            Platform agnostic system
          </Heading>
          <Text fontSize={3} lineHeight={1.6}>
            Develop a single, unified system that is transferrable across all
            platforms and devices.
          </Text>
        </Box>
        <Box marginBottom={[4, 4, 4, 0]} px={3} width={[1, 1, 1, 1 / 3]}>
          <Heading fontSize={4} fontWeight={500} mb={2}>
            Expedite the design process
          </Heading>
          <Text fontSize={3} lineHeight={1.6}>
            By abstracting our base styles layer, developers can churn out UIs
            with minimal design input.
          </Text>
        </Box>
      </FlexWrap>

      <Box marginTop={7}>
        <Heading as="h2" fontSize={6}>
          FAQs
        </Heading>
      </Box>
      <FlexWrap marginBottom={[6, 7, 104]} marginTop={5} space={space[3]}>
        <Box marginBottom={[4, 4, 4, 0]} px={3} width={[1, 1, 1, 1 / 2]}>
          <Box marginBottom={[4, 4, 4, 6]}>
            <Heading fontSize={4} fontWeight={500} mb={2}>
              What is the Marvel styleguide?
            </Heading>
            <Text lineHeight={1.6}>
              Our styleguide is a live inventory of UI components, design
              patterns, brand assets and code guidelines which the whole Marvel
              team work from, keeping all of our products cohesive.
            </Text>
          </Box>
          <Heading fontSize={4} fontWeight={500} mb={2}>
            Do Marvel products use this styleguide?
          </Heading>
          <Text lineHeight={1.6}>
            This styleguide is built on the exact same codebase as each of our
            live products. This styleguide is the central hub — new updates are
            pushed here first, then trickle down to our live products.
          </Text>
        </Box>
        <Box marginBottom={[4, 4, 4, 0]} px={3} width={[1, 1, 1, 1 / 2]}>
          <Box marginBottom={[4, 4, 4, 6]}>
            <Heading fontSize={4} fontWeight={500} mb={2}>
              Will this Marvel styleguide be open-sourced?
            </Heading>
            <Text lineHeight={1.6}>
              For the moment, we have not yet open-sourced, mainly because it is
              still heavily in flux. But yes, at some point in the near future
              we are planning to package it up and make it easily accessible to
              everyone.
            </Text>
          </Box>
          <Heading fontSize={4} fontWeight={500} mb={2}>
            Can I use this styleguide in my own work?
          </Heading>
          <Text lineHeight={1.6}>
            Sure, go ahead! We are planning to open-source our styleguide soon,
            so feel free to use it any way you wish when we do.
          </Text>
        </Box>
      </FlexWrap>
    </Page>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    build: file(absolutePath: { regex: "/build@2x.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
