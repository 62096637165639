// When modifying this file, make sure to add the appropriate redirects in gatsby-node.js
export const SIDEBAR_LINKS = [
  {
    name: 'Overview',
    to: '/overview',
    sublinks: [
      { name: 'Introduction', to: '/overview/introduction' },
      { name: 'Getting started', to: '/overview/getting-started' },
      { name: 'Tech stack', to: '/overview/tech-stack' },
      { name: 'Responsive', to: '/overview/responsive' },
    ],
  },
  {
    name: 'Design',
    to: '/design',
    sublinks: [
      { name: 'Brand', to: '/design/brand' },
      { name: 'Typeface', to: '/design/typeface' },
      { name: 'Colors', to: '/design/colors' },
      { name: 'Spacing', to: '/design/spacing' },
      { name: 'Box shadow', to: '/design/box-shadow' },
      { name: 'Border radius', to: '/design/border-radius' },
    ],
  },
  {
    name: 'Components',
    to: '/components',
    sublinks: [
      { name: 'Avatar', to: '/components/avatar' },
      { name: 'Box', to: '/components/box' },
      { name: 'Button group', to: '/components/button-group' },
      { name: 'Button', to: '/components/button' },
      { name: 'Card', to: '/components/card' },
      { name: 'Checkbox', to: '/components/checkbox' },
      { name: 'Collaborators', to: '/components/collaborators' },
      { name: 'Flex', to: '/components/flex' },
      { name: 'Floaty', to: '/components/floaty' },
      { name: 'Folder', to: '/components/folder' },
      { name: 'Grid', to: '/components/grid' },
      { name: 'Heading', to: '/components/heading' },
      { name: 'Hint bar', to: '/components/hint-bar' },
      { name: 'Icon', to: '/components/icon' },
      { name: 'Image', to: '/components/image' },
      { name: 'Input', to: '/components/input' },
      { name: 'Link', to: '/components/link' },
      { name: 'List view', to: '/components/list-view' },
      { name: 'Loader', to: '/components/loader' },
      { name: 'Logo', to: '/components/logo' },
      { name: 'Message', to: '/components/message' },
      { name: 'Notification bar', to: '/components/notification-bar' },
      { name: 'Popover', to: '/components/popover' },
      { name: 'Radio', to: '/components/radio' },
      { name: 'Range slider', to: '/components/range-slider' },
      { name: 'Select', to: '/components/select' },
      { name: 'Settings item', to: '/components/settings-item' },
      { name: 'Simple list item', to: '/components/simple-list-item' },
      { name: 'Slim upseller', to: '/components/slim-upseller' },
      { name: 'Stepper', to: '/components/stepper' },
      { name: 'Text list', to: '/components/text-list' },
      { name: 'Text', to: '/components/text' },
      { name: 'Textarea', to: '/components/textarea' },
      { name: 'Tiny upseller', to: '/components/tiny-upseller' },
      { name: 'Toggle', to: '/components/toggle' },
      { name: 'Tooltip', to: '/components/tooltip' },
      { name: 'Upseller', to: '/components/upseller' },
    ],
  },
  {
    name: 'Utilities',
    to: '/utilities',
    sublinks: [
      { name: 'Position', to: '/utilities/position' },
      { name: 'Pinning', to: '/utilities/pinning' },
    ],
  },
];
